import style from "@/Ui_Elements/OrangeSpan/OrangeSpan.module.scss"

const OrangeSpan = ({classname, text}) => {
  return (
    <div className={`${style["orangespan"]} ${classname}`}>
        <span className={style["textspan"]}>{text}</span>
    </div>
  )
}

export default OrangeSpan;