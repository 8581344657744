"use client";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import Image from "next/image";
import style from "@/styles/Bannerswiper.module.scss";

const data = {
  bannertitle: "TRUSTED BY 15K+ BUSINESSES TO SCALE OUTBOUND",

  images: [
    { source: "/images/banner/swiper_logo/1.png", alt: "Image 1" },
    { source: "/images/banner/swiper_logo/2.png", alt: "Image 3" },
    { source: "/images/banner/swiper_logo/3.png", alt: "Image 4" },
    { source: "/images/banner/swiper_logo/4.png", alt: "Image 5" },
    { source: "/images/banner/swiper_logo/5.png", alt: "Image 6" },
    { source: "/images/banner/swiper_logo/6.png", alt: "Image 8" },
    { source: "/images/banner/swiper_logo/7.png", alt: "Image 9" },
    { source: "/images/banner/swiper_logo/8.png", alt: "Image 4" },
    { source: "/images/banner/swiper_logo/9.png", alt: "Image 5" },
    { source: "/images/banner/swiper_logo/10.png", alt: "Image 6" },
    { source: "/images/banner/swiper_logo/11.png", alt: "Image 8" },
    { source: "/images/banner/swiper_logo/12.png", alt: "Image 9" },
  ],
};

const Bannerswiper = (props) => {
  const { images, bannertitle } = data;
  return (
    <section className={`${style["bannerswiper"]}`}>
      <div className="container">
        <h1 className={`${style["bannertitle"]}`}> {bannertitle}</h1>
      </div>
      <div className="container-fluid">
        <Swiper
          loop={true}
          navigation={true}
          spaceBetween={50}
          draggable={true}
          slidesPerView={6}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination]}
          breakpoints={{
            1200: {
              slidesPerView: 8,
            },
            900: {
              slidesPerView: 4,
            },
            600: {
              slidesPerView: 3,
            },
            0: {
              slidesPerView: 2,
            },
          }}
          // style={{ width: "100%", height: "auto" }} // Ensure full width
        >
          {data.images.map((image, index) => (
            <SwiperSlide key={index}>
              <div className={`${style["logo_main_image"]}`}>
                <Image
                  src={image.source}
                  alt={`Logo ${index}`}
                  width={0}
                  height={0}
                  className={`${style["logo_image"]}`}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Bannerswiper;
