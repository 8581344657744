import Image from "next/image";
import style from "./CommonCard.module.scss";

const CommonCard = ({
  type = 1,
  classname,
  classname2,
  backgroundcolor = "#F9791D",
  title,
  description,
  src,
  classname3,
}) => {
  if (type === 1) {
    return (
      <div className={`${style["singlecard"]} ${classname}`}>
        <div className={`${style["icondiv"]} ${classname2}`} style={{ backgroundColor: backgroundcolor }}>
          <Image className={style["icon"]} src={src} alt="featureicon" width={0} height={0} />
        </div>

        <h3 className={style["cardtitle"]}>{title}</h3>
        <p className={style["carddescription"]}>{description} </p>
      </div>
    );
  }
  if (type === 2) {
    return (
      <>
        <div className={`${style["singlecard"]} ${style["titleiconrowcard"]} ${classname}`}>
          <div className={style["icontitlerow"]}>
          <div className={`${style["icondiv"]} ${classname2}`}>
            <Image className={style["icon"]} src={src} alt="featureicon" width={0} height={0} />
          </div>

          <h4 className={`${style["cardtitle"]} ${classname3}`}>{title}</h4>
          </div>
          
          <p className={style["carddescription"]}>{description} </p>
        </div>
      </>
    );
  }
};

export default CommonCard;
