"use client"
import React from "react";
import style from "@/Ui_Elements/Li/Li.module.scss";
import Image from "next/image";

const Li = ({ type = 1, classname, text }) => {
  function createMarkup(content) {
    return { __html: content };
  }
  if (type === 1) {
    return (
      <li className={`${classname} ${style["singlelist"]}`}>
        <div className={`${style["left"]}`}>
          <div className={`${style["icondiv"]}`}>
            <Image src="/images/icons/tick_circle.svg" alt="tick" width={0} height={0} />
          </div>
        </div>
        <div className={`${style["right"]}`}>
          <p className={`${style["listext"]}`} dangerouslySetInnerHTML={createMarkup(text)}></p>
        </div>
      </li>
    );
  }
};

export default Li;
