"use client";
import React from "react";
import style from "@/styles/InformationAbout.module.scss";
import OrangeSpan from "@/Ui_Elements/OrangeSpan/OrangeSpan";
import Button_Common from "@/Ui_Elements/Button_Common";
import Li from "@/Ui_Elements/Li/Li";
import Image from "next/image";
import CommonCard from "@/Ui_Elements/CommonCard/CommonCard";

// "RowReverceFlagStart" : true,
// "sections":[]

const InformationAbout = (props) => {
  const { sections, RowReverceFlagStart } = props.data;
  function createMarkup(content) {
    return { __html: content };
  }

  const RowReverceLogic = (index) => {
    if (RowReverceFlagStart) {
      return index % 2 === 0;
    } else {
      return index % 2 !== 0;
    }
    // RowReverceFlagStart ? index % 2 === 0 : index % 2 !== 0
  };

  const data = props.data;

  return (
    <>
    {sections.map((item, index)=>(
       <section key={index}
       className={`${style["informationmaindiv"]} informationmaindiv commnabgshape ${item.sectionbgshapeclass ? item.sectionbgshapeclass : ""}`}
     >
       <div className={`container`}>
         <div className={`${style["infomainrow"]} ${RowReverceLogic(index) ? "RowReverce" : ""}`}>
           <div className={`${style["imagedivpart"]}`}>
             <div className={`${style["imagebox"]} ${RowReverceLogic(index) ? style["specialmarginclass"] : ""} `}>
               <Image src={item.image} alt="image" width={0} height={0} />
             </div>
           </div>
           <div className={`${style["contentpart"]} my-auto`}>
             {item?.orangespan && <OrangeSpan text={item.orangespan} />}

             {/* <h3 className={`commonsectiontitle`}>{item.title}</h3> */}
             <h3 className="commonsectiontitle" dangerouslySetInnerHTML={createMarkup(item.title)} />
             {item?.secondtitle && <h4 className="commonsectionsecondtitle" dangerouslySetInnerHTML={createMarkup(item.secondtitle)} />}

             <p className={`commonparatext ${style["infodescription"]}`}>{item.description}</p>
             {item.points && (
               <ul className={`${style["liststyleone"]}`}>
                 {item.points && item.points.map((item, index) => <Li key={index} text={item} classname={"Infolist"} />)}
               </ul>
             )}
             {item.cards && (
               <div className={`${style["cardscontainer"]}`}>
                 {item.cards.map((item, index) => (
                   <div key={index} className={`${style["cardwidth"]}`}>
                     <CommonCard classname={style["infosinglecard"]} title={item.cardtitle} description={item.carddescription} src={item.image} />
                   </div>
                 ))}
               </div>
             )}
             {item.buttontext && (
               <div className={`${style["buttondiv"]}`}>
                 <Button_Common href={item.buttonlink} text={item.buttontext} type={item?.buttontype} />
               </div>
             )}
           </div>
         </div>
       </div>
     </section>
    ))}
     
    </>
  );
};

export default InformationAbout;
